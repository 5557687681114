import styled from 'styled-components'
import { device } from '../../theme/mediaQueries'

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;

  #l-layout__main {
    /* min-height: 500px; */
    min-height: calc(100vh - 152px);
    /* overflow: hidden; */
    position: relative;
    width: 100%;
  }
  #modal-root {
    top: 0;
    position: fixed;
    z-index: 9999;
  }

  .l-layout__imageLeft{
    bottom: -250px;
    position: absolute;
    width: 100px;
    z-index: -1;
  }

  .l-layout__imageRight {
    position: absolute;
    width: 200px;
    top: -60px;
    right: 0;
  }

  @media ${device.sm} {
    #l-layout__main {
      min-height: calc(100vh - 176px);
    }

    .l-layout__imageRight {
      width: 220px;
      top: 0px;
    }
    .l-layout__imageLeft{
      bottom: -200px;
    }
  }
  @media ${device.md} {
    #l-layout__main {
      min-height: calc(100vh - 190px);
    }
  }
  @media ${device.lg} {
    #l-layout__main {
      min-height: calc(100vh - 204px);
    }
    .l-layout__imageRight {
      width: 300px;
    }
    .l-layout__imageLeft{
      bottom: -200px;
      width: 150px;
    }
  }
  @media ${device.xl} {
    #l-layout__main {
      min-height: calc(100vh - 223px);
    }
  }
`
export default LayoutStyled
