import React from "react"
import Seo from "../components/seo/Seo"
import DigitalReadings from "../components/modules/digitalReadings/DigitalReadings"

const DigitalReadingsPage = () => {
  return <DigitalReadings />
}

export default DigitalReadingsPage

export const Head = () => <Seo title={'Lectura digital'} />;
