import { useState } from 'react'
import { getAccounts, updateAccount, createAccount, updatePassword, forgotPassword, deleteAccount, digitalReadings } from '../services/accounts'
import { signUp, verificationCode, verify } from '../services/signUp'
import { invoices, invoicesSanitation, pdfInvoices, xmlInvoices } from '../services/invoices'
import wsErrors from '../../static/data/errors.json'

const useSiteData = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [accounts, setAccounts] = useState([]);
  const [detailsSummary, setDetailsSummary] = useState([]);
  const [successDetailsPayment, setSuccessDetailsPayment] = useState({})
  const [loading, setloading] = useState(false)
  const [loadAccount, setLoadAccount] = useState(null)
  const [error, setError] = useState(null)
  const [headerWhite, setHeaderWhite] = useState(false)
  const [myDigitalReadings, setMyDigitalReadings] = useState(null)

  const getErrorByType = (errorType, soapError) => { 
    const errorObject = Object.values(wsErrors).find(e => e.error === errorType);
    const errorMessage = errorObject?.message || wsErrors['unexpected_error'].message;
    if (errorType === 'soap_error') {
      const message = errorMessage + " " + soapError
      return message
    } else {
      return errorMessage
    }
  }

  const fetchAccounts = async () => {
    setLoadAccount(true)
    try {
      const response = await getAccounts()
      setAccounts(response.data)
      setLoadAccount(false)
    } catch (error) {
      setLoadAccount(false)
    }
  }

  const getDigitalReadings = async (accountNumber) => { 
    setloading(true)
    try {
      const response = await digitalReadings(accountNumber)
      setloading(false)
      setMyDigitalReadings(response.data)
      return response.data
    } catch (error) {
      setloading(false)
      return setMyDigitalReadings(null)
    }
  }

  const updateAliasAccount = async (id, label) => { 
    setloading(true)
    try {
      const response = await updateAccount(id, label)
      setAccounts(response.data)
      setloading(false)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      
      setloading(false)
      return errorMessage
    }
  }

  const deleteOneAccount = async (id) => { 
    try {
      const response = await deleteAccount(id)
      setAccounts(response.data)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
    }
  }
  const createNewAccount = async ({ accountNumber, label, meterId }) => { 
    setloading(true)

    try {
      const response = await createAccount(accountNumber, label, meterId)
      setAccounts(response.data)
      setloading(false)
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
      setloading(false) 
    }
  }

  const changeUserPassword = async (password, newPassword) => { 
    setloading(true)

    try {
      const response = await updatePassword(password, newPassword)
      setAccounts(response.data)
      setError(false)
      setloading(false)
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(true)
      setloading(false) 
      return errorMessage
    }
  }

  const recoverPassword = async (email) => { 
    setloading(true)

    try {
      const response = await forgotPassword(email)
      setError(false)
      setloading(false)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(true)
      setloading(false) 
      return errorMessage
    }
  }

  const signUpAccount = async (name, surnames, email, password, phoneNumber, accountNumber, label, meterId) => {
    setloading(true)
    try {
      const response = await signUp(
        name, surnames, email, password, phoneNumber,
        accountNumber, label, meterId
      );

      const { data } = response;
      setError(false)
      setloading(false)
      return data; 
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
      setloading(false) 
      return error
    }
  };

  const sendVerificationCode = async (phoneNumber) => { 
    setloading(true)
    try {
      const response = await verificationCode(phoneNumber)
      setloading(false)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
      setloading(false) 
      return error
    }
  }

  const getVerify = async (verificationCode, phoneNumber) => { 
    setloading(true)
    try {
      const response = await verify(verificationCode, phoneNumber)
      setloading(false)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
      setloading(false) 
      return error
    }
  }

  const getHistory = async (accountNumber) => { 
    setloading(true)
    try {
      const response = await invoices(accountNumber)
      setloading(false)
      return response
    } catch (error) {
      const errorType = error.response.data.error;
      const message = error.response.data.message
      const errorMessage = getErrorByType(errorType, message)
      setError(errorMessage)
      setloading(false) 
      throw new Error(error);
    }
  }

  const getSanitationInvoice = async (accountNumber) => { 
    setloading(true)
    try {
      const response = await invoicesSanitation(accountNumber)
      setloading(false)
      return response
    } catch (error) {
      setloading(false)
      throw new Error(error);
    }
  }

  const getPdfInvoice = async (invoiceNumber, accountNumber) => { 
    try {
      const response = await pdfInvoices(invoiceNumber, accountNumber)
      return response
    } catch (error) {
      throw new Error(error);
    }
  }

  const getXmlInvoice = async (invoiceNumber, accountNumber) => { 
    try {
      const response = await xmlInvoices(invoiceNumber, accountNumber)
      return response
    } catch (error) {
      throw new Error(error);
    }
  }


  return {
    isOpenModal,
    currentIndex,
    error,
    accounts,
    loading,
    detailsSummary,
    successDetailsPayment,
    loadAccount,
    headerWhite,
    myDigitalReadings,
    setLoadAccount,
    fetchAccounts,
    setIsOpenModal,
    setCurrentIndex,
    setDetailsSummary,
    setAccounts,
    setloading,
    updateAliasAccount,
    createNewAccount,
    deleteOneAccount,
    changeUserPassword,
    recoverPassword,
    signUpAccount,
    sendVerificationCode,
    getVerify,
    getHistory,
    getSanitationInvoice,
    getPdfInvoice,
    getXmlInvoice,
    setSuccessDetailsPayment,
    setHeaderWhite,
    getDigitalReadings
  }
}

export default useSiteData
