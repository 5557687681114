import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const StyleDropdown = styled.div`
  position: relative;
  width: 100%;

  .react-select__control {
    display: flex;
    border: solid 2px;
    border-radius: 20px 0;
    cursor: pointer;

    &.react-select__control--menu-is-open {
      border-radius: 20px 0 0 0;
      border-bottom: none;
    }
  }
  .react-select__value-container {
    padding: 20px 20px 20px 30px;
  }
  .react-select__input-container {
    margin: 0;
    padding: 0;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__indicator {
    padding: 0 20px 0 0;
  }
  .react-select__placeholder,
  .react-select__single-value {
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 18px;
    font-weight: ${props => props.theme.fonts.fontBold};
    margin-left: 0;
    margin-right: 0;
    line-height: 1em;
  }
  .react-select__placeholder {
    opacity: 0.7;
  }
  .react-select__input {
    font-weight: ${props => props.theme.fonts.fontBold} !important;
  }
  .react-select__menu {
    border: solid 3px;
    border-top: none;
    border-radius: 0 0 20px 0;
    box-shadow: none;
    margin: 0;
    padding: 0 20px 20px 20px;
  }
  .react-select__menu-list {
    border-top: solid 2px;
    margin: 0;
    max-height: 250px !important;
    padding: 0;

    .react-select__no-results,
    .react-select__loading {
      display: block;
      margin-top: 20px;
    }
    .react-select__option:first-child {
      margin-top: 20px;
    }
  }
  .react-select__option {
    background-color: transparent;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
    padding: 10px;
  }

  .react-select__container-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .react-select__option--is-selected {
    font-weight: ${props => props.theme.fonts.fontBold};
  }
  .react-select__container-single-values {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .react-select__container-single-values span:first-child {
    margin-right: 16px;
  }
  .react-select__single-value,
  .react-select__placeholder,
  .react-select__input-container,
  .react-select__indicator,
  .react-select__indicator:hover {
    color: ${props => props.theme.colors.black};
    line-height: 1.1;
  }
  .react-select__menu {
    position: relative;
    z-index: 2;
  }
  .react-select--is-disabled {
    opacity: 0.6;
  }

  &.has-icon {
    border-radius: 10px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;

    .m-dropdown__icon-container {
      align-items: center;
      display: flex;
      left: 24px;
      position: absolute;
      height: 60px;
      z-index: 1;
    }
    .m-dropdown__icon {
      height: 26px;
      width: 26px;
    }
    .react-select__control {
      border: none;
      border-radius: 0;

      &.react-select__control--menu-is-open {
        border-radius: 0;
      }
    }
    .react-select__value-container {
      background-color: ${props => props.theme.colors.white};
      padding: 20px 20px 20px 70px;
    }
    .react-select__indicators {
      background-color: ${props => props.theme.colors.white};
    }
    .react-select__menu {
      border: none;
      border-radius: 0;
    }
    .react-select__menu-list {
      border-top: solid 2px;

      .react-select__option:first-child {
        margin-top: 20px;
      }
    }
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.pink};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
  &.is-pink {
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.pink};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
  &.is-pinked {
    .react-select__control {
      background-color: ${props => props.theme.colors.pink};
      border: none;
    }
    .react-select__menu {
      border: solid 2px ${props => props.theme.colors.pink};
      border-top: none;
    }
    .react-select__menu-list {
      border-top: none;
    }
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container {
      color: ${props => props.theme.colors.white};
    }

    .react-select__option--is-selected {
      color: ${props => props.theme.colors.pink};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.white};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.white};
    }
  }
  &.is-pink-small {
    .react-select__value-container {
      padding: 11px 20px 11px 25px;
    }
    .react-select__placeholder,
    .react-select__single-value {
      font-size: 14px;
    }
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.pink};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__option {
      font-size: 14px;
      line-height: 1.2;
      margin: 0;
      padding: 5px;
    }
  }
  &.is-blue {
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
  }
  &.is-green {
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.green};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
  }

  &.is-blue-dark-icon-pink {
    .react-select__single-value,
    .react-select__placeholder,
    .react-select__input-container,
    .react-select__option--is-selected {
      color: ${props => props.theme.colors.blueDark};
    }
    .react-select__indicator {
      color: ${props => props.theme.colors.pink};
    }
    .react-select__indicator:hover {
      color: ${props => props.theme.colors.blue};
    }
    .react-select__control {
      border: solid 2px ${props => props.theme.colors.blue};
    }
  }

  @media ${device.lg} {
    .react-select__value-container {
      padding: 23px 20px 23px 30px;
    }
    .react-select__dropdown-indicator {
      svg {
        height: 26px;
        width: 26px;
      }
    }
    &.has-icon {
      .m-dropdown__icon-container {
        height: 66px;
      }
      .m-dropdown__icon {
        height: 28px;
        width: 28px;
      }
      .react-select__placeholder,
      .react-select__single-value {
        font-size: 20px;
      }
      .react-select__value-container {
        padding: 23px 20px 23px 80px;
      }
    }
  }
`
export default StyleDropdown
