import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const DigitalReadingsStyled = styled.div`
  margin: 20px 0 140px;
  padding: 0 16px;
  position: relative;

  .m-digital-readings__button-back {
    margin-bottom: 30px;
  }
  .m-digital-readings__select {
    margin: 60px auto 0 auto;
    max-width: 380px;
    width: 100%;
  }
  .m-digital-readings__no-data-container {
    margin: 60px auto 0 auto;
    max-width: 380px;
    text-align: center;
  }
  .m-digital-readings__no-data-container img {
    margin-bottom: 40px;
  }
  .m-digital-readings__no-data-container h3 {
    color: ${({ theme }) => theme.colors.blue};
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  @media ${device.sm} {
    padding: 0 42px;
  }

  @media ${device.md} {
    margin-top: 30px;
    padding: 0 50px;
  }

  @media ${device.lg} {
    margin-top: 70px;
    padding: 0 60px;
    .m-digital-readings__no-data-container {
      margin: 80px auto 0 auto;
      max-width: 430px;
    }
  }

  @media ${device.xl} {
    width: 1744px;
    padding: 0;
    margin: 70px auto 140px;
  }
`
