import React, { useState, useEffect, useRef } from "react"
import { withPrefix } from "gatsby"

import { shape, string } from "prop-types";
import lottie from 'lottie-web'

import  { ReadingViewStyled } from "./ReadingView.style";

const ReadingView = ({ data, rateType }) => { 
  const classNameID = `m-reading-view`
  const { ammount, averageConsumption, consumption, reading } = data
  const lottieContainerRef = useRef(null);
  const lottieRef = useRef(null);
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    const currentLottie = lottieContainerRef.current;
    const path = `${withPrefix("/")}assets/lotties`
    if (!currentLottie) return;

    const createLottie = () => { 
      lottieRef.current = lottie.loadAnimation({
        container: currentLottie,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: `${path}/simulator-graph.json`,
      });

      lottieRef.current.addEventListener('DOMLoaded', () => {
        setAnimation(lottieRef.current);
      });
    }
    createLottie();
    return () => {
      lottieRef.current?.destroy();
    };

  }, [lottieContainerRef.current]);

  useEffect(() => {
    if (!animation) return;

    const maxConsumption = averageConsumption * 2;
    const progress = Math.min(Math.max(reading / maxConsumption, 0), 1.0);
    const totalFrames = animation.totalFrames;
    const targetFrame = progress * totalFrames;

    animation.goToAndStop(0, true);

    const duration = 500;
    const frameRate = animation.frameRate;
    const speedMultiplier = (1000 / duration) * (targetFrame / frameRate);

    animation.setSpeed(speedMultiplier);
    animation.playSegments([0, targetFrame], true);
  }, [animation, consumption]);
 
  return (
    <ReadingViewStyled className={`${classNameID}`} >
      <div className={`${classNameID}__top-container`}>
        <div ref={lottieContainerRef} className={`${classNameID}__lottie`} />
        <div className={`${classNameID}__content`}>
          <div className={`${classNameID}__fee-container`}>
            <p>Tarifa</p>
            <p>{rateType}</p>
          </div>
          <div className={`${classNameID}__ammount-container`}>
            <p>$ {ammount}</p>
            <p>Total a pagar</p>
          </div>
          <div className={`${classNameID}__consumption-container`}>
            <p>Consumo :</p>
            <div className={`${classNameID}__average-container`}>
              <p>Bajo</p>
              <p>{averageConsumption} m<sup>3</sup> </p>
              <p>Alto</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classNameID}__bottom-container`} >
        <div className={`${classNameID}__current-reading`}>
          <p>Lectura Actual:</p>
          <span>{consumption.toString()}</span>
        </div>
        <p> 
          *Esta simulación se basa en los datos del usuario. Ten en cuenta que la simulación tiene un desfase de 1 hora.
        </p>
      </div>
    </ReadingViewStyled>
  )

};

ReadingView.propTypes = {
  data: shape({}).isRequired,
  rateType: string.isRequired,
}

ReadingView.defaultProps = {
  data: {},
  rateType: "",
}

export default ReadingView;
