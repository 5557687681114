import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const ReadingViewStyled = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;

  .m-reading-view__top-container {
    margin: 30px auto 0;
    position: relative;
    height: 300px;
    width: 300px;
  }
  .m-reading-view__lottie {
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
  }
  .m-reading-view__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .m-reading-view__fee-container {
    padding-top: 45px;
    text-align: center;
  }
  .m-reading-view__fee-container p:first-of-type {
    color: ${props => props.theme.colors.blue};
    font-size: 18px;
  }
  .m-reading-view__fee-container p:last-of-type {
    color: ${props => props.theme.colors.blueDark};
    font-size: 20px;
    text-transform: lowercase;
  }
  .m-reading-view__fee-container p:last-of-type::first-letter {
    text-transform: uppercase; 
  }
  .m-reading-view__ammount-container {
    text-align: center;
  }
  .m-reading-view__ammount-container p:first-of-type {
    color: ${props => props.theme.colors.blue};
    font-size: 30px;
    font-weight: 700;
  }
  .m-reading-view__ammount-container p:last-of-type {
    color: ${props => props.theme.colors.blueDark};
    font-size: 18px;
  }
  .m-reading-view__consumption-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .m-reading-view__consumption-container p:first-of-type {
    color: ${props => props.theme.colors.blueDark};
    font-size: 18px;
  }
  .m-reading-view__average-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .m-reading-view__average-container p:first-of-type {
    color: ${props => props.theme.colors.blue};
    font-size: 18px;
    font-weight: 600;
  }
  .m-reading-view__average-container p {
    color: ${props => props.theme.colors.pink};
    font-size: 18px;
    font-weight: 600;
  }
  .m-reading-view__average-container sup {
    font-size: 12px;
  }
  .m-reading-view__bottom-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px auto 0;
    max-width: 380px;
  }
  .m-reading-view__current-reading {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%;
  }
  .m-reading-view__current-reading p {
    color: ${props => props.theme.colors.blue};
    font-size: 18px;
    font-weight: 600;
  }
  .m-reading-view__current-reading span {
    font-size: 24px;
    font-weight: 600;
  }
  .m-reading-view__bottom-container p {
    text-align: center;
  }

  @media ${device.lg} {
    .m-reading-view__top-container {
      height: 400px;
      width: 400px;
    }
    .m-reading-view__fee-container {
      padding-top: 60px;
    }
    .m-reading-view__consumption-container {
      padding-bottom: 15px;
    }
    .m-reading-view__bottom-container {
      margin-top: 20px;
    }
    .m-reading-view__fee-container p:first-of-type,
    .m-reading-view__ammount-container p:last-of-type,
    .m-reading-view__consumption-container p:first-of-type,
    .m-reading-view__average-container p:first-of-type,
    .m-reading-view__average-container p,
    .m-reading-view__current-reading p {
      font-size: 24px;
    }
    .m-reading-view__fee-container p:last-of-type {
      font-size: 24px;
    }
    .m-reading-view__ammount-container p:first-of-type {
      font-size: 40px;
    }
  }
`
