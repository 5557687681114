import React, { useEffect, useContext } from "react"
import SiteContext from "../../../context/site.context"

import TextTitle from "../blocks/title/TextTitle"
import Cards from "../blocks/cards/Cards"

import svgCard from "../../../images/svgs/cards.svg"
import svgHistorial from "../../../images/svgs/historial.svg"
import svgContratos from "../../../images/svgs/contratos.svg"
import svgReadings from "../../../images/svgs/lectura-digital.svg"

import DashboardStyled from "./DashboardOficinaVirtual.style"

const DashboardOficinaVirtual = () => {
  const classNameID = `m-dashboard`
  const { setloading, fetchAccounts } = useContext(SiteContext)

  useEffect(() => {
    setloading(false)
  }, [])

  return (
    <DashboardStyled id="dashboard" className={classNameID}>
      <TextTitle textBlue="Oficina" textGreen="Virtual" />
      <div className={`${classNameID}__container-cards`}>
        <Cards title="Pago en línea" to='/pago-en-linea' onClick={ () => fetchAccounts()}>
          <img src={svgCard} alt="cards" />
        </Cards>
        <Cards title="Historial de consumo" to='/historial' onClick={ () => fetchAccounts()} >
          <img src={svgHistorial} alt="historial" />
        </Cards>
        <Cards title="Contratos" to="/contratos" onClick={ () => fetchAccounts()} >
          <img src={svgContratos} alt="contratos" />
        </Cards>
        <Cards title="Mi lectura digital" to="/lectura-digital" onClick={ () => fetchAccounts()} >
          <img src={svgReadings} alt="lectura digital" />
        </Cards>
      </div>
    </DashboardStyled>
  )
}

export default DashboardOficinaVirtual
