/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

require('./src/styles/globals.scss');

const ReactDOM = require('react-dom/client');
const React = require('react')
const Layout = require('./src/components/layout/Layout').default
const { Router } = require('@reach/router')
const LoginPage = require('./src/pages/login').default
const Cuenta = require('./src/pages/cuenta').default
const Index = require('./src/pages/index').default
const CambiarContrasenaPage = require('./src/pages/cambiar-contrasena').default
const PayOnLinePage = require('./src/pages/pago-en-linea').default
const ContractsPage = require('./src/pages/contratos').default
const HistorialPage = require('./src/pages/historial').default
const RegistroPage = require('./src/pages/registro').default
const DigitalReadings = require('./src/pages/lectura-digital').default
const RecuperarContrasenaPage = require('./src/pages/recuperar-contrasena').default
const RestablecerContrasenaPage = require('./src/pages/restablecer-contrasena').default
const PrivateRoute = require('./src/components/privateRoute/PrivateRoute').default
const NotFoundPage = require('./src/pages/404').default

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout >
        <Router basepath='/'>
          {/* <Redirect from="/" to="/login" noThrow />  */}
          {/* <Redirect from="/" to="/" noThrow />  */}
          <LoginPage path="/login" />
          <RegistroPage path="/registro" />
          <RecuperarContrasenaPage path="/recuperar-contrasena" />
          <RestablecerContrasenaPage path="/restablecer-contrasena" />
          <Index path="/" />
          <PrivateRoute path="/cuenta" component={Cuenta} />
          <PrivateRoute path="/contratos" component={ContractsPage} />
          <PrivateRoute path="/pago-en-linea" component={PayOnLinePage} />
          <PrivateRoute path="/historial" component={HistorialPage} />
          <PrivateRoute path="/cambiar-contrasena" component={CambiarContrasenaPage} />
          <PrivateRoute path="/lectura-digital" component={DigitalReadings} />
          <NotFoundPage default />
        </Router>
      </Layout>
    </>
  )
}

exports.onInitialClientRender = () => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 0)
}
