import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const DashboardStyled = styled.div`
  margin-top: 50px;

  .m-dashboard__container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 16px 100px 16px;
  }

  @media ${device.sm} {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .m-dashboard__container-cards {
      margin: 0 auto;
      max-width: 800px;
      padding: 100px 30px;
      row-gap: 50px;
    }
  }

  @media ${device.md} {
    margin-top: 115px;
    .m-dashboard__container-cards {
      padding: 100px 0;
    }
  }

  @media ${device.lg} {
    .m-dashboard__container-cards {
      max-width: 1100px;
      row-gap: 60px;
    }
  }

  @media ${device.xl} {
    .m-dashboard__container-cards {
      max-width: 1550px;
      width: 1550px;
    }
  }
`;


export default DashboardStyled;
