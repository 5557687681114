import React, { useState, useContext, useEffect } from "react"
import SiteContext from "../../../context/site.context"

import Modal from "../modal/Modal"
import TextTitle from "../blocks/title/TextTitle"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Dropdown from "../../elements/dropdown/Dropdown"
import LoadingView from "../loading/LoadingView"
import ReadingView from "./ReadingView"
import svgReadings from "../../../images/svgs/lectura-digital.svg"

import { DigitalReadingsStyled } from "./DigitalReadings.style";
import CustomAlert from "../../elements/alerts/CustomAlert"

const DigitalReadings = () => { 
  const classNameID = `m-digital-readings`
  const { currentIndex, setCurrentIndex, loadAccount, fetchAccounts, getDigitalReadings, accounts, myDigitalReadings, loading } = useContext(SiteContext)
  const [showModal, setShowModal] = useState(false)
  const [options, setOptions] = useState([])
  const [rateType, setRateType] = useState("")

  useEffect(() => {
    if (loadAccount === null && accounts.length === 0) {
      fetchAccounts()
    }
  }, [loadAccount])

  useEffect(() => {
    const theOptions = []
    if (accounts.length > 0) {
      for (let i = 0; i < accounts.length; i += 1) {
        const { label, accountNumber } = accounts[i]
        theOptions.push({ value: i, label: label, labelRight: accountNumber })
      }
    }
    setOptions(theOptions)
  }, [accounts])

  useEffect(() => { 
    if (accounts && accounts.length > 0 && currentIndex !== null) {
      const accountNumber = accounts[currentIndex].accountNumber
      setRateType(accounts[currentIndex].rateType)
      getDigitalReadings(accountNumber)
    }
  }, [accounts, currentIndex])

  useEffect(() => {
    if (myDigitalReadings !== null && !loading) {
      setTimeout(() => {
        const { highConsumption } = myDigitalReadings
        setShowModal(highConsumption)
      }, 1000);
    }
  }, [loading, myDigitalReadings]);

  const buildAlert = () => {
    return (
      <CustomAlert
        onClose={() => {
          setShowModal(false)
        }}
        title="Mi Lectura Digital"
        description="Tu lectura digital tiene un alto consumo, por favor verifica que no tengas fugas de agua."
        buttonTitle="Aceptar"
        isError={true}
      />
    )
  }

  const buidlDigitalReadingsView = () => { 
    return (
      <>
        <ButtonArrowLeft to="/cuenta" className={`${classNameID}__button-back`}>
          Regresar
        </ButtonArrowLeft>
        { 
          options.length > 0 && (
            <>
              <TextTitle textBlue="Mi lectura" textGreen="digital" />
              <div className={`${classNameID}__container_select`}>
                <Dropdown
                  config={{
                    options: options,
                    defaultValue: options[currentIndex],
                    onChange: e => setCurrentIndex(e.value),
                  }}
                  className={`${classNameID}__select is-blue-dark-icon-pink `}
                  id={`select__contract`}
                />
              </div>
            </>
          )
        }
        { loading && <LoadingView />}
        { !loading && myDigitalReadings !== null && <ReadingView data={myDigitalReadings} rateType={rateType} /> }
        { !loading && myDigitalReadings === null &&
          <div className={`${classNameID}__no-data-container`}>
            <img src={svgReadings} alt="lectura digital" />
            <h3>Tú contrato no cuenta con lectura digital.</h3>
            <p>Tu contrato no incluye la opción de lectura digital.
              Si deseas actualizarlo o explorar otras opciones, contáctanos para más información.
            </p>
          </div>
        }  
      </>
    )
  }

  return (
    <DigitalReadingsStyled className={classNameID} >
      {loadAccount ? <LoadingView /> : buidlDigitalReadingsView()}
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          {buildAlert()}
        </Modal>
      )}
    </DigitalReadingsStyled >
  );
}

export default DigitalReadings;
