import styled from 'styled-components'
import { Link } from "gatsby"

import { device } from '../../../../theme/mediaQueries'

export const CardLink = styled(Link)`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 380px;
  height: 100px;
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 26px;
  font-family: ${props => props.theme.fonts.jeko};
  color: ${props => props.theme.colors.blue};
  font-weight: ${props => props.theme.fonts.fontBold};
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 20px 0px;

  &:hover {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  img,
  svg {
    height: auto;
    min-width: 50px;
    padding: 0 25px;
    width: 50px;
  }

  .m-card__title{
      padding-right: 15px;
  }

  @media ${device.sm} {
    width: 210px;
    height: 210px;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 40px 0px;

    img, svg {
      width: 66px;
      height: 66px;
      padding: 0;
      margin-bottom: 25px;
    }

    .m-card__title{
      padding: 0 20px;
      text-align: center;
      font-size: 20px;
    }
  }

  @media ${device.md}{
    width: 237px;
    height: 237px;

    img, svg{
      width: 80px;
      height: 80px;
    }

    .m-card__title{
      font-size: 22px;
    }
  }

  @media ${device.lg}{
    width: 317px;
    height: 317px;

    img, svg{
      width: 100px;
      height: 100px;
      margin-bottom: 35px;
    }

    .m-card__title{
      font-size: 30px;
      padding: 0 35px;
    }

  }

  @media ${device.xl}{
    width: 351px;
  }
`

export const CardButton = styled(CardLink).attrs({ as: 'div' })``
