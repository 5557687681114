import styled from "styled-components";
import { device } from "../../../theme/mediaQueries";

export const AlertContent = styled.div`
  margin: 0 auto;
  display: block;
  position: relative;
  width: 90%;
  height: 550px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  text-align: center;

  @media ${device.sm} {
    width: 450px;
    height: 570px;
  }

  @media ${device.lg} {
    width: 500px;
    height: 600px;
  }

  .m-alert-content__alert-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .m-alert-content__button{
      width: 250px;
      margin: 0 auto 20px;
    }
    .m-alert-content__button-cancel {
      margin: 0 auto;
    }
  }
  .m-alert-content__input {
    margin: 0 auto 40px;
  }
`

export const StyleAlertImage = styled.div`
  text-align: center;
  svg{
      width: 90px;
      height: 90px;
      margin-top: 60px;
  }

  @media ${device.lg} {
    svg{
      width: 113px;
      height: 113px;
      margin-top: 76px;
    }
  }
   
`

export const StyleAlertText = styled.div`
  width: 100%;
  padding: 45px 30px;
  text-align: center;
  .m-alert-content__title{
    color: ${props => props.theme.colors.blue};
    font-size: 26px;
    font-weight: ${props => props.theme.fonts.fontExtraBold};
  }
  

  @media ${device.sm} {
    .m-alert-content__title{
      font-size: 32px;
    }
  }

  @media ${device.lg} {
    padding: 55px 30px;
  }
`
